// PaymentWrapper.jsx
import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import CheckoutForm from "./CheckoutForm"; // Uisti sa, že cesta je správna
import Loader from "./Loader";

// Načítanie Stripe objektu
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentWrapper = () => {
  const { client_id } = useParams();
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [error, setError] = useState("");

  // Načítanie Stripe a získanie clientSecret
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_POCKETBASE_URL}/api/stripe/payment-intent`, {
          customerId: client_id,
        });
        console.log("Client Secret fetched:", response.data.clientSecret);
        setClientSecret(response.data.clientSecret);
        setPaymentIntentId(response.data.paymentIntentId);
      } catch (error) {
        console.error("Chyba pri načítaní clientSecret:", error);
        setError("Nepodarilo sa inicializovať platbu. Skúste to znova.");
      }
    };

    fetchClientSecret();
  }, [client_id]);

  // Memoizujte options pre Elements
  const options = useMemo(() => ({
    clientSecret,
  }), [clientSecret]);

  if (error) {
    return <p className="text-red-500 text-center mt-10">{error}</p>;
  }

  if (!clientSecret) {
    return <Loader />;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm clientSecret={clientSecret} paymentIntentId={paymentIntentId} />
    </Elements>
  );
};

export default PaymentWrapper;
