import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const AdminBase = ({ children }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-blue-500 text-white py-4 px-6">
        <h1 className="text-2xl font-semibold text-center">SazkovaArena</h1>
      </header>

      {/* Main Content */}
      <main className="flex-1 p-4 bg-gray-100">{children}</main>

      {/* Bottom Navigation */}
      <nav className="bg-white shadow-md fixed bottom-0 left-0 w-full flex justify-around items-center py-2">
        <Link
          to="/admin/dashboard"
          className={`flex flex-col items-center text-sm ${
            location.pathname === '/admin/dashboard' ? 'text-blue-500' : 'text-gray-500'
          } hover:text-blue-500`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 10h11m0 0l-4-4m4 4l-4 4m5 5h5a2 2 0 002-2V7a2 2 0 00-2-2h-5"
            />
          </svg>
          Dashboard
        </Link>
        <Link
          to="/admin/logout"
          className={`flex flex-col items-center text-sm ${
            location.pathname === '/admin/logout' ? 'text-red-500' : 'text-gray-500'
          } hover:text-red-500`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 16l4-4m0 0l-4-4m4 4H7"
            />
          </svg>
          Odhlásiť sa
        </Link>
      </nav>
    </div>
  );
};

export default AdminBase;
