import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminBase from './Base';
import PocketBase from 'pocketbase';

const Dashboard = () => {
  const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
  const navigate = useNavigate(); // Pre presmerovanie na prihlasovaciu stránku
  const [userData, setUserData] = useState({
    wallet: 0,
    iban: '',
    email: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const checkAuthAndFetchUserData = async () => {
      if (!pb.authStore.isValid) {
        navigate('/admin/auth'); // Presmerovanie na prihlasovaciu stránku, ak nie je prihlásený
        return;
      }

      try {
        // Vypnutie automatického rušenia požiadaviek
        pb.autoCancellation(false);

        const userId = pb.authStore.model?.id; // Získať ID aktuálneho používateľa
        if (!userId) {
          throw new Error('Nepodarilo sa načítať údaje používateľa.');
        }
        const userRecord = await pb.collection('users').getOne(userId);
        setUserData({
          wallet: userRecord.wallet || 0,
          iban: userRecord.iban || 'N/A',
          email: userRecord.email || '',
        });
      } catch (err) {
        setError('Chyba pri načítavaní údajov.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    checkAuthAndFetchUserData();
  }, [pb, navigate]);

  if (loading) {
    return (
      <AdminBase>
        <div className="flex items-center justify-center h-full">
          <p>Načítavanie...</p>
        </div>
      </AdminBase>
    );
  }

  if (error) {
    return (
      <AdminBase>
        <div className="flex items-center justify-center h-full">
          <p className="text-red-500">{error}</p>
        </div>
      </AdminBase>
    );
  }

  return (
    <AdminBase>
      <div className="px-6 py-8 bg-white rounded-md shadow-md">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Dashboard</h2>

        {/* Zostatok v peňaženke */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-gray-700">Zostatok v peňaženke</h3>
          <p className="text-2xl font-semibold text-blue-600">{userData.wallet} EUR</p>
        </div>

        {/* Referenčný odkaz */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-gray-700">Váš referenčný odkaz</h3>
          <p className="text-sm text-gray-600 mb-2">
            Zdieľajte tento odkaz so svojimi priateľmi a získajte provízie.
          </p>
          <input
            type="text"
            value={`https://sazkovaarena.cz/platba?referall=${pb.authStore.model?.id}`}
            readOnly
            className="w-full p-2 border rounded-md bg-gray-100 text-gray-600 focus:outline-none"
          />
          <p className="text-sm text-gray-600 mt-2">
            Výška provízie: <span className="font-bold text-blue-600">30%</span> z každého nákupu cez váš odkaz.
          </p>
        </div>

        {/* IBAN */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-gray-700">IBAN</h3>
          <p className="text-lg text-gray-800">{userData.iban}</p>
        </div>

        {/* Výplaty */}
        <div>
          <h3 className="text-xl font-medium text-gray-700">Výplaty</h3>
          <p className="text-sm text-gray-600">
            Výplata provízií prebieha <span className="font-bold">každý pondelok</span>.
          </p>
        </div>
      </div>
    </AdminBase>
  );
};

export default Dashboard;
