// Loader.jsx
import React from "react";

const Loader = () => (
  <div className="flex items-center justify-center h-screen">
    <p>Načítavam platobnú bránu...</p>
  </div>
);

export default Loader;
