import React from 'react';

const Home = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-3xl bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-blue-500 text-center mb-6">
          Vitajte na SazkovaArena
        </h1>
        <p className="text-lg text-gray-700 text-center mb-4">
          Ponúkame <span className="font-semibold text-blue-500">profesionálne poradenstvo</span> v oblasti tipov na zápasy.
          Naše tipy sú výsledkom dlhoročných skúseností a starostlivej analýzy.
          Poskytujeme vám <span className="font-semibold text-blue-500">vysokú úspešnosť až 93%</span>.
        </p>
        <p className="text-lg text-gray-700 text-center mb-4">
          Naše poradenstvo nie je len o tipovaní – je to <span className="font-semibold text-blue-500">vaša cesta k finančnej slobode.</span>
          Pridajte sa k stovkám spokojných zákazníkov, ktorí si užívajú istotu našich odporúčaní.
        </p>
        <p className="text-lg text-gray-700 text-center mb-4">
          <span className="font-semibold text-blue-500">Prečo si vybrať nás?</span> Naše služby sú založené na faktoch,
          dôkladnej analýze a dlhodobých výsledkoch. Garantujeme, že naše tipy vám pomôžu dosahovať stabilné zisky.
        </p>
        <p className="text-lg text-gray-700 text-center mb-4">
          Tipovanie s nami nie je len šanca na výhru, ale aj <span className="font-semibold text-blue-500">skutočný biznis.</span> 
          Naša odbornosť a zameranie na detaily zabezpečuje, že naši klienti dosahujú úspech na každodennej báze.
        </p>
        <p className="text-lg text-gray-700 text-center mb-6">
          S nami máte prístup k <span className="font-semibold text-blue-500">exkluzívnym tipom</span>, ktoré nie sú verejne dostupné.
          Váš úspech je našou prioritou – urobte prvý krok smerom k lepšej budúcnosti už dnes!
        </p>
        <div className="text-center">
        </div>
        <p className="text-sm text-gray-500 text-center">
          <span className="font-semibold">SazkovaArena</span> – vaše partnerstvo pre výhry. 
          Kontaktujte nás a staňte sa súčasťou komunity úspešných tipérov.
        </p>
      </div>
    </div>
  );
};

export default Home;
