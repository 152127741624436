import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

const Auth = () => {
  const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Kontrola, či je už užívateľ prihlásený
    if (pb.authStore.isValid) {
      navigate('/admin/dashboard');
    }
  }, [pb.authStore, navigate]);

  const handleLogin = async () => {
    setError('');
    try {
      await pb.collection('users').authWithPassword(email, password);
      console.log('User ID:', pb.authStore.record.id);
      navigate('/admin/dashboard'); // Presmerovanie po úspešnom prihlásení
    } catch (err) {
      setError('Prihlásenie zlyhalo. Skontrolujte email a heslo.');
      console.error('Error:', err);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin(); // Pri stlačení Enter spustiť prihlasovaciu funkciu
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-center text-blue-500">Prihlásenie</h1>
        <div className="mt-6">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Zadajte email"
            className="w-full mt-1 p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
            onKeyPress={handleKeyPress} // Pridanie detekcie stlačenia Enter
          />
        </div>
        <div className="mt-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Heslo
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Zadajte heslo"
            className="w-full mt-1 p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
            onKeyPress={handleKeyPress} // Pridanie detekcie stlačenia Enter
          />
        </div>
        {error && (
          <p className="mt-4 text-sm text-red-500 text-center">{error}</p>
        )}
        <button
          onClick={handleLogin}
          className="w-full mt-6 p-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
        >
          Prihlásiť sa
        </button>
      </div>
    </div>
  );
};

export default Auth;
