import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

const Logout = () => {
  const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      // Odhlásenie používateľa
      pb.authStore.clear(); // Vymaže uložené tokeny a autentifikačné údaje
      navigate('/admin/auth'); // Presmeruje na prihlasovaciu stránku
    };

    logoutUser();
  }, [pb, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <p className="text-gray-700 text-lg">Odhlasujem vás...</p>
    </div>
  );
};

export default Logout;
