import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './components/admin/Auth';
import Dashboard from './components/admin/Dashboard';
import Logout from './components/admin/Logout';
import Home from './components/public/Home';
import CheckoutWrapper from './components/public/PaymentForm';
import CreateCustomer from './components/public/NewClient';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/platba" element={<CreateCustomer />} />
          <Route path="/platba/customer/:client_id" element={<CheckoutWrapper />} />
          <Route path="/admin/auth" element={<Auth />} />
          <Route path="/admin/logout" element={<Logout />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          {/* Stránka nenájdená */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

const NotFound = () => (
  <div>
    <h2>Stránka nenájdená</h2>
    <p>Táto stránka neexistuje.</p>
  </div>
);

export default App;
