import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";

const CheckoutForm = ({ clientSecret, paymentIntentId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [cardReady, setCardReady] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 1;
  const [cardKey, setCardKey] = useState(0);

  useEffect(() => {
    if (paymentIntentId) {
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_POCKETBASE_URL}/api/stripe/payment-status/${paymentIntentId}`
          );
          const paymentStatus = response.data.paymentStatus;

          if (paymentStatus === "paid") {
            setStatus("Platba prebehla úspešne.");
            clearInterval(interval);
          } else if (paymentStatus === "failed") {
            setStatus("Platba zlyhala. Skúste to znova.");
            clearInterval(interval);
          }
        } catch (error) {
          setStatus("Chyba pri overovaní stavu platby.");
          clearInterval(interval);
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [paymentIntentId]);

  useEffect(() => {
    let timeoutId;

    if (!cardReady && retryCount < maxRetries) {
      timeoutId = setTimeout(() => {
        setRetryCount((prev) => prev + 1);
        setCardKey((prev) => prev + 1);
      }, 5000);
    } else if (retryCount >= maxRetries) {
      window.location.reload();
    }

    return () => clearTimeout(timeoutId);
  }, [cardReady, retryCount]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setIsProcessing(true);

    if (!stripe || !elements) {
      setError("Stripe.js sa nenačítalo. Skúste to znova.");
      setIsProcessing(false);
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setError("CardElement sa nenačítalo správne. Skúste to znova.");
        setIsProcessing(false);
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        setStatus("Platba prebieha. Overujem stav...");
      }
    } catch (error) {
      setError("Platba zlyhala. Skúste to znova.");
    } finally {
      setIsProcessing(false);
    }
  };

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form
    onSubmit={handleSubmit}
    className="space-y-6 max-w-md mx-auto bg-white shadow-lg rounded-lg p-6 mt-6"
  >
    <h1 className="text-3xl font-bold text-center text-blue-600 mb-4">
      SazkovaArena
    </h1>
    <p className="text-gray-800 text-center text-lg mb-6">
      Získajte <strong>profesionálne tipy na zápasy</strong>
      <br />
      len za <span className="text-blue-500 font-semibold">1,99&nbsp;€</span>!
    </p>

  
    <div className="bg-blue-100 p-4 rounded-lg text-sm text-blue-800">
      <ul className="list-disc pl-4">
        <li>Tipy od skúsených profesionálov</li>
        <li>Zvýšte svoje šance na výhru</li>
        <li>Jednoduchá a rýchla platba</li>
      </ul>
    </div>
  
    <div className="bg-gray-50 p-4 rounded-lg text-sm text-gray-800 mt-4">
      <p>
        💡 Už za cenu kávy môžete spraviť krok bližšie k svojej{" "}
        <strong>finančnej nezávislosti</strong>.
      </p>
      <p className="mt-2">
        Zmeňte svoj spôsob tipovania a získajte hodnotné informácie, ktoré vám
        pomôžu poraziť bookmakerov.
      </p>
    </div>
  
    <div className="text-center mt-4">
      <p className="text-sm text-gray-600 italic">
        98% našich zákazníkov odporúča naše tipy!
      </p>
    </div>
  
    {error && (
      <p className="text-red-500 text-center bg-red-100 p-2 rounded">{error}</p>
    )}
    {status && (
      <p className="text-blue-500 text-center bg-blue-100 p-2 rounded">
        {status}
      </p>
    )}
  
    <div className="border p-4 rounded-lg bg-gray-50">
      <CardElement
        key={cardKey}
        options={cardStyle}
        onReady={() => setCardReady(true)}
        onChange={(event) => {
          if (event.error) {
            setError(event.error.message);
          } else {
            setError(null);
          }
        }}
      />
    </div>
  
    {cardReady ? (
      <>
        <div className="flex items-start mb-4">
          <input
            type="checkbox"
            id="terms"
            className="mt-1 mr-2"
            required
          />
          <label htmlFor="terms" className="text-sm text-gray-600">
            Súhlasím s{" "}
            <a
              href="/terms.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              obchodnými podmienkami
            </a>
            .
          </label>
        </div>
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className="bg-blue-500 text-white w-full px-4 py-2 rounded-lg font-semibold hover:bg-blue-600"
        >
          {isProcessing ? "Spracováva sa..." : "Zaplatiť 1,99 €"}
        </button>
      </>
    ) : (
      <div className="flex justify-center">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    )}
  
    <div className="mt-6 text-center">
      <p className="text-sm text-gray-600">
        Máte otázky? Kontaktujte nás na{" "}
        <a
          href="mailto:support@sazkovaarena.cz"
          className="text-blue-500 hover:underline"
        >
          support@sazkovaarena.cz
        </a>
        .
      </p>
    </div>
  </form>
  


  );
};

export default CheckoutForm;
